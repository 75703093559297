* {
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
@tailwind base;
body {
  font-family: "Inter", sans-serif;
}
@tailwind components;
@tailwind utilities;

/* body {
    font-family: 'sans-serif';
} */
/* body {
  font-family: 'Work Sans Variable', sans-serif;
} */
/* body {
  font-family: "Sue Ellen Francisco", sans-serif;
} */
