@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400..900&family=Source+Sans+3:wght@200..900&display=swap');

$destopContainerMargin: 5rem;
// C O L O R S

$desktopTotalMargin: $destopContainerMargin * 2;

$primaryColor: #ff6d6d;
$secondaryColor: #77d7d3;
$infoColor: #2e4049;

$cremita: #f1eeec;
$lightBlue: #ecf1f0;
$lightGray: #f2f2f2;
$lightBlack: #727272;

$font-main: '"Playfair Display", sans-serif';
$font-primary: '"Playfair Display", sans-serif';
$font-secondary: '"Playfair Display", sans-serif';
